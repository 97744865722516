<template>
    <div>
        <vx-card title="Deposit Payment">
            <vs-tabs :color="colorx" v-model="tabs">
                <vs-tab @click="colorx = 'danger'" label="Open">
                    <div class="con-tab-ejemplo">
                        <open/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'warning'" label="Confirmed">
                    <div class="con-tab-ejemplo">
                        <confirmed/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Waiting Reconcile">
                    <div class="con-tab-ejemplo">
                        <reconcile />
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'primary'" label="Done">
                    <div class="con-tab-ejemplo">
                        <done />
                    </div>
                </vs-tab>
                
            </vs-tabs>
        </vx-card>
    </div>
</template>
<script>
import open from "./open.vue";
import confirmed from "./confirmed.vue";
import reconcile from "./reconcile.vue";
import done from "./done.vue";
// import waiting from "./waiting-payment.vue";

export default {
    components: {
        open,
        confirmed,
        reconcile,
        done
    },
    data:() => ({
        colorx: "danger",
        tabs: 0,
    })
}
</script>