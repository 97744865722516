<template>
  <div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <!-- 
       ADDONs
        -->
        <div>
          <vs-button v-on:click="create('deposit')" icon="done" color="primary" style="margin-bottom:15px;">
            Create Deposit Invoice
          </vs-button>
        </div>
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.invoices"
              v-bind:class="[selectedData.ID == tr.ID ? 'colored' : '', '']"
              style="
                 {
                  border: 1px solid;
                }
              "
            >
              <vs-button
                v-if="selectedData.ID == tr.ID"
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >

              <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
              <vs-td>
                <template>
                  <!-- hide ganti cara payment -->
                  <vx-tooltip text="Set Confirm">
                    <vs-button
                      color="success"
                      type="line"
                      icon-pack="feather"
                      @click="handleValid(tr)"
                      icon="icon-check-square"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Edit Invoices">
                    <vs-button
                      color="warning"
                      type="line"
                      icon-pack="feather"
                      @click="handleEdit(tr)"
                      icon="icon-edit"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Cancel Invoice">
                    <vs-button
                      color="danger"
                      type="line"
                      icon-pack="feather"
                      @click="handleCancel(tr)"
                      icon="icon-x-square"
                    />
                  </vx-tooltip>
                </template>
              </vs-td>
              <vs-td>
                Code : {{ tr.Code }}
                <br />
                Due Date : {{ dateFormat(tr.DueDate) }}
                <br />
              </vs-td>
              <vs-td>
                Code : {{ tr.ClientCode }}
                <br />
                Name : {{ tr.ClientName }}
                <br />
              </vs-td>
              <vs-td>
                {{ priceFormat(tr.Total) }}
                <br />
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <!-- detail-->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <create
              @closeDetail="closeDetail"
              :mode="mode"
              :invoice="selectedData"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import {
  deleteInvoiceDeposit,
  updateInvoiceDeposit,
} from "../../../../services/api/payment";
import create from "./create.vue";
import moment from "moment";
export default {
  components: {
    create,
    // "form-payment": form,
  },
  props: {
    selected: Object,
    option: Object,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "code",
      },
      activePrompt: false,
      promptCancel: {},
      noteCancel: "",
      mode: "",
      header: [
        {
          text: "Action",
          sortable: false,
          width: "5%",
        },
        {
          text: "Invoice",
          value: "code",
          // width: '5%'
        },
        {
          text: "Client",
          sortable: false,
        },
        {
          text: "Amount",
          sortable: false,
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {
        ID:0
      },
      cancelData: {},
    };
  },
  computed: {},
  watch: {
    selectedData: function () {},
  },
  mounted() {},
  methods: {
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    handleValid: function (selected) {
      this.cancelData = selected;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure approve invoice " + selected.Code + " ?",
        accept: this.confirmInvoice,
        cancel: () => {},
      });
    },
    handleEdit: function (selected) {
      this.selectedData = selected;
      this.mode = "deposit";
      this.detail = true;
    },
    create: function (mode = "payment") {
      this.mode = mode;
      console.log(this.mode);
      this.detail = true;
    },
    handleCancel: function (selected) {
      this.cancelData = selected;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure cancel invoice " + selected.Code + " ?",
        accept: this.confimCancel,
        cancel: () => {},
      });
    },
    confimCancel() {
      this.$vs.loading();
      const request = {
        type: 2,
        status: 0,
        invoice_id: this.cancelData.ID,
      };
      const post = deleteInvoiceDeposit(request);
      post.then((r) => {
        this.cancelData = {};
        if (r.code == 500) {
          this.$vs.notify({
            title: "Failed",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "warning",
          });
        } else if (r.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "success",
          });
        }
        this.$nextTick(() => {
          // this.$emit("closeDetail");
          this.$emit("closeDetail", true);
          this.reloadData(this.params)
          this.$vs.loading.close();
        });
        console.log(r);
      });
    },
    confirmInvoice() {
      this.$vs.loading();
      const request = {
        type: 2,
        status: 1,
        invoice_id: this.cancelData.ID,
      };
      const post = updateInvoiceDeposit(request);
      post.then((r) => {
        this.cancelData = {};
        this.$vs.loading.close();
        if (r.code == 500) {
          this.$vs.notify({
            title: "Failed",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "warning",
          });
        } else if (r.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "success",
          });
        }
        this.$nextTick(() => {
          // this.$emit("closeDetail");
          this.$emit("closeDetail", true);
          this.reloadData(this.params)
          this.$vs.loading.close();
        });
        console.log(r);
      });
    },
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    sendEmail(invoice) {
      this.$http.get("api/v1/invoice/send/" + invoice).then((resp) => {
        console.log(resp);
      });
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();
      this.$http
        .get("api/v1/invoice/data-table", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            status: 0,
            type: 2,
          },
        })
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.data = resp.data.invoices;
            this.responseData = resp.data;
            // this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
      // this.$http
      // .get("/api/v1/master/price-rule", {
      //   params: {
      //     search: params.search,
      //     length: params.length,
      //     page: params.page,
      //     order: "",
      //     sort: "",
      //   }
      // })
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>