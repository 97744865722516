<template>
  <div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="thead">
        <!-- <th width="15%">
              <vs-checkbox color="success" v-on:click="addAllValidate()" v-model="validateAll">Release All</vs-checkbox>
            </th> -->
      </template>
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.payment">
          <vs-td>
              {{tr.InvoiceCode}}
          </vs-td>
          <vs-td>
            {{ priceFormat(tr.InvoicePaymentAmount) }}
          </vs-td>
          
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>
<script>
import { getPaymentLine } from "../../../../services/api/payment";
export default {
  props: {
    payment: Object,
  },
  watch: {
      payment(){
            this.reloadData(this.params)
      }
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "created_at",
      },
      header: [
        {
          text: "Invoice",
          value: "invoice_code",
          // width: '5%'
        },
        {
          text: "Payment",
          value: "invoice_payment_amount",
        }
      ],
      uploadReady: true,
      responseData: {},
      selectedData: {},
    };
  },
  mounted() {
    
  },
  methods: {
    reloadData(params) {
        this.$vs.loading();
      this.params = params;
      this.params.payment_id = this.payment.ID
    //   console.log(params);
      const dataTable = getPaymentLine(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.payment;
          this.responseData = r.data;
          this.responseData.length = r.data.payment.length;
          this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
};
</script>